/**
 * API Helpers
 *
 * @module lib/helpers/apiHelpers
 */

import fetch from 'isomorphic-fetch'
import config from '@pwc/frontend-env'
import QueryString from 'query-string'

const API_URL = config('REACT_APP_BACKEND_URL')

/**
 * Function to invoke the api
 * @method callApi
 */
export function callApi(
  endpoint,
  {
    method = 'get',
    params = {},
    isJSON = true
  } = {}) {
  const headers = {}

  if (isJSON) {
    headers['content-type'] = 'application/json'
  }

  let reqBody = isJSON ? JSON.stringify(params) : params
  let queryString = ''
  if (method === 'get') {
    reqBody = null // Can't have a body on a get request, will silently fail
    if (params && Object.keys(params).length) queryString = `?${QueryString.stringify(params)}`
  }
  return fetch(`${API_URL}/${endpoint}${queryString}`, {
    headers,
    method,
    body: reqBody,
    credentials: 'same-origin'
  })
  .then((response) => {
    if (!response.ok) {
      if (!response.bodyUsed) return Promise.reject(response)
      return response.json().then(json => Promise.reject({...response, ...json}))
    }
    return response.json()
  })
}
