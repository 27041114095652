import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'appkit-react'
import ConfirmationModal from './confirmationModal'

/**
 * Button for delete a new record (for a specified mutation)
 * When clicked it displays a form to confirm deletion
 * @method      DeleteRecordButton
 * @constructor
 */
export default function DeleteRecordButton({
  className,
  buttonText,
  title,
  message,
  onDelete,
  size
}) {
  const [isModalOpen, setModalState] = useState(false)
  const toggleModal = () => setModalState(!isModalOpen)

  return (
    <React.Fragment>
      <Button
        kind="secondary"
        size={size || 'sm'}
        className={className}
        onClick={toggleModal}
      >
        {buttonText}
      </Button>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        handleCloseModal={toggleModal}
        title={title}
        message={message}
        onConfirm={onDelete}
      />
    </React.Fragment>
  )
}

DeleteRecordButton.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  onDelete: PropTypes.func,
  size: PropTypes.string
}
