import Component from '../components/header'
import { graphql } from 'react-apollo'
import { compose, withProps } from 'recompose'
import { logout } from 'components/auth/actions/authActions'
import { USER_PROFILE_QUERY } from 'components/user/queries/profileQueries'

export default compose(
  graphql(USER_PROFILE_QUERY),
  withProps(() => {
    return {
      async handleLogout() {
        await logout()
        window.location = 'https://www.pwc.com.au'
      }
    }
  })
)(Component)
