/**
 * Contains various query helper methods
 * Mostly for post mutate functions to update the cache
 */

import { ALL_RULE_ACTIONS_BY_RULE, ALL_RULES_BY_RULSET } from './rulesetQueries'

/**
 *
 * @param {object} cache Apollo cache
 * @param {object} ruleAction The updated rule action
 */
export const updateCachePostRuleActionCreated = (
  cache,
  {
    data: {
      createRuleAction: { ruleAction },
    },
  }
) => {
  const { allRuleActions } = cache.readQuery({
    query: ALL_RULE_ACTIONS_BY_RULE,
    variables: { ruleId: ruleAction.ruleId },
  })
  cache.writeQuery({
    query: ALL_RULE_ACTIONS_BY_RULE,
    variables: { ruleId: ruleAction.ruleId },
    data: {
      allRuleActions: {
        ...allRuleActions,
        nodes: allRuleActions.nodes.concat([ruleAction]),
      },
    },
  })
}

/**
 * Method for updating apollo cache to remove deleted rule action
 * @param {object} cache Apollo cache object
 * @param {object} ruleAction Rule action object that was deleted
 */
export const updateCachePostRuleActionDelete = (
  cache,
  {
    data: {
      deleteRuleActionById: { ruleAction },
    },
  }
) => {
  const { allRuleActions } = cache.readQuery({
    query: ALL_RULE_ACTIONS_BY_RULE,
    variables: { ruleId: ruleAction.ruleId },
  })
  cache.writeQuery({
    query: ALL_RULE_ACTIONS_BY_RULE,
    variables: { ruleId: ruleAction.ruleId },
    data: {
      allRuleActions: {
        ...allRuleActions,
        nodes: allRuleActions.nodes.filter((x) => x.id !== ruleAction.id),
      },
    },
  })
}