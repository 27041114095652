import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'appkit-react'
import CreateRecordModal from './createRecordModal'

/**
 * Button for creating a new record (for a specified mutation)
 * or updating a new one
 * When clicked it displays a form in a modal for the desired record
 * If an icon is provided instead of buttonText then an icon is displayed instead
 * @method      CreateRecordModalButton
 * @constructor
 */
export default function CreateRecordButton({
  className,
  buttonText,
  Form,
  title,
  record,
  size,
  icon,
}) {
  const [isModalOpen, setModalState] = useState(false)
  const toggleModal = () => setModalState(!isModalOpen)

  return (
    <React.Fragment>
      {!icon && (
        <Button
          type="primary"
          size={size || 'lg'}
          className={className}
          onClick={toggleModal}
        >
          {buttonText}
        </Button>
      )}
      {icon && <span className={`appkiticon ${icon}`} onClick={toggleModal} />}
      <CreateRecordModal
        Form={Form}
        isModalOpen={isModalOpen}
        handleCloseModal={toggleModal}
        title={title}
        record={record}
      />
    </React.Fragment>
  )
}

CreateRecordButton.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  Form: PropTypes.func,
  size: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.string,
}
