import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Breadcrumb, BreadcrumbItem } from "appkit-react";
import styles from '../stylesheets/subheader.module.css';
import logo from 'appkit-react/style/images/component/pwc_logo_header_2x.png';
import config from '@pwc/frontend-env'

const subheaderLinks = [
  { path: '/review/project-summary', label: 'Project summary' },
  { path: '/review/processing-status', label: 'Processing status' },
  // { path: '/review/vat/reconciliations', label: 'Reconciliations' },
  { path: '/review/files/pending', label: 'Unreviewed invoices' },
  { path: '/review/files/reviewed', label: 'Reviewed invoices' },
]

export default class Subheader extends React.Component {

  render() {
    const currentUrl = this.props.location.pathname
    const items = subheaderLinks.map(link => {
      const isActive = currentUrl.substr(0, link.path.length) === link.path
      const linkClass = `${styles.breadcrumbLink} ${isActive ? 'a-breadcrumb-active' : ''}`
      return {
        link: link.path,
        value: <span className={linkClass}>{link.label}</span>,
      }
    })

    return (
      <div className='breadcrumb-story-container'>
        <Breadcrumb items={items} separator="|" className={styles.breadcrumbsMenu} />
      </div>

    );
  }
}
