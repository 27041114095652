import React from 'react'
import { Modal, Button, ModalHeader, ModalBody } from 'appkit-react'
import PropTypes from 'prop-types'
import { ModalFooter } from 'appkit-react/lib/Modal'
import styles from '../stylesheets/confirmationModal.module.css'

/**
 * Modal that uses frontier forms to display a create record form
 * @method      CreateRecordModal
 * @constructor
 */
export default function CreateRecordModal({
  isModalOpen,
  handleCloseModal,
  title,
  message,
  onConfirm,
}) {
  const cancelMessage = message || 'Are you sure you wish to continue?'
  const handleConfirmClicked = () => {
    handleCloseModal()
    onConfirm()
  }
  return (
    <Modal
      className="action-modal form-modal"
      visible={isModalOpen}
      onCancel={handleCloseModal}
    >
      <ModalHeader>
        <div className="modal-title">{title || 'Please confirm'}</div>
      </ModalHeader>
      <ModalBody>{cancelMessage}</ModalBody>
      <ModalFooter className={styles.confirmationModalFooter}>
        <Button kind="transparent" onClick={handleCloseModal}>Cancel</Button>
        <Button kind="primary" onClick={handleConfirmClicked}>Confirm</Button>
      </ModalFooter>
    </Modal>
  )
}

CreateRecordModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  mutation: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
}
