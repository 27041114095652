import React from "react";
import PropTypes from "prop-types";

export default function FieldWrapper(props) {
  return (
    <div className="form-group">
      {props.type == "object" ? (
        props.children
      ) : (
        <React.Fragment>
          <div className="a-h6">{props.label || props.path}</div>
          {props.children}
          {!!props.error && (props.dirty || props.submitFailed) && (
            <label basic color="red" pointing="above">
              {props.error == "required"
                ? "This field is required"
                : "There is an error"}
            </label>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

FieldWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  type: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  dirty: PropTypes.bool,
  submitFailed: PropTypes.bool
};
