/**
 * User profile queries
 */
import gql from 'graphql-tag'

export const USER_PROFILE_QUERY = gql`
  query CurrentUserForLayout {
    currentAppUser {
      id
      firstName
      lastName
    }
  }
`
