/**
 * Contains middleware for apollo that injects the authorization header
 * into apollo requests
 */

import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'

export const authLink = setContext((_, { headers }) => {
  return {
    ...headers
  }
})

export const errorHandler = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (
        message === "invalid token" ||
        message === "jwt malformed" ||
        message === "user token has no role" ||
        message.indexOf("permission denied for") > -1
      ) {
        if (
          `${window.location.origin}/` !== window.location.href &&
          window.location.pathname !== "/home" &&
          window.location.pathname.indexOf("signin") === -1
        ) {
          window.location.href = `${window.location.origin}/`;
        }
      }
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})
