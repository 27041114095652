import config from '@pwc/frontend-env'

/**
 * Helper method for inspecting an apollo data object and returning
 * the state of data.loading
 *
 * In some cases we never want to show loading info app wide (e.g. due to hot module reloading)
 * so we can override it here
 * @method isLoading
 */
export const isLoading = (apolloData) => {
  if (config('HMR_ENABLED') === 'true') return false
  if (!apolloData) return false
  return apolloData.loading
}
