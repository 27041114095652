import React from 'react';
import {
  Panel,
} from 'appkit-react'
import styles from '../stylesheets/dashboard.module.css'
import CreateRecordButton from 'components/common/components/createRecordButton'
import RulesetForm from './rulesetForm'

export default class WelcomePanel extends React.Component {

  render() {
    return (
      <div className="contact-panel d-table w-100">
        <div className="d-flex flex-wrap flex-sm-nowrap">
          <div className="a-mb-20">
            <Panel className="a-p-20">
              <div className="a-text-heading a-mt-5">
                AirTax Automated Accountant
              </div>
              <div className="a-text-caption font-weight-medium a-mt-10 a-text-616">
                This application allows you to manage the rules used by the automated
                accountant for the purpose of reviewing tax returns
              </div>
              <div className="a-text-caption font-weight-medium a-mt-10 a-text-616">
                You can start using the application by choosing a ruleset to manage
                on the right or creating a new one
              </div>

              <div className={styles.newRulesetButtonContainer}>
                <CreateRecordButton
                  className={styles.newRulesetButton}
                  buttonText="Create Ruleset"
                  Form={RulesetForm}
                  title="Create Ruleset"
                />
              </div>
            </Panel>
          </div>
        </div>
      </div>
    )
  }
}
