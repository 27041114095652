import React, { Component } from 'react';
import styles from '../stylesheets/footer.module.css'

export default class Footer extends Component {
  render() {
    return (
      <div
        className={`a-footer ${styles.footer}`}
        title="&copy; 2017 - 2019 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details. Liability limited by a scheme approved under Professional Standards Legislation."
      >
        &copy; 2017 - 2019 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details. Liability limited by a scheme approved under Professional Standards Legislation.
        <br/>
        v: {process.env.REACT_APP_VERSION} ({process.env.REACT_APP_VERSION_SHA})
      </div>
    );
  }
}
