import React from 'react'
import PropTypes from 'prop-types'
import { Frontier } from '@pwc/frontier-forms'
import uiKit from 'components/ui-kit/uiKit'
import {
  CREATE_RULE_CRITERION,
  UPDATE_RULE_CRITERION,
  ALL_OPERATORS,
  DELETE_RULE_CRITERION,
  RULE_FRAGMENT,
  SEARCH_FIELDS_BY_NAME,
} from '../queries/rulesetQueries'
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks'
import ReactSelect from 'react-select/async-creatable'
import { Select, SelectOption, InputNumber } from 'appkit-react'
import styles from '../stylesheets/rulesets.module.css'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _sortBy from 'lodash/sortBy'
import _debounce from 'lodash/debounce'
import debounce from 'debounce-promise'

export default function RuleCriterionForm({
  criterion,
  createNewCriterion,
  rulesetId,
}) {
  const client = useApolloClient()
  const [deleteRuleCriterion] = useMutation(DELETE_RULE_CRITERION)
  const { loading: operatorsLoading, data: operatorsData } = useQuery(
    ALL_OPERATORS
  )
  let mutation = UPDATE_RULE_CRITERION

  // Check if we're waiting for data
  if (operatorsLoading) return <div />
  const operators = operatorsData.allOperators.nodes
  let pathPrefix = 'input.ruleCriterionPatch'

  // Check if this record has been saved yet, if not use the create mutation
  if (criterion.id < 0) {
    mutation = CREATE_RULE_CRITERION
    pathPrefix = 'input.ruleCriterion'
  }

  // Initial values
  const initialValues = { input: { id: criterion.id } }
  _set(initialValues, pathPrefix, _omit(criterion, ['__typename']))
  // Check if this is a new row not yet saved, if so delete some stuff to conform to the different
  // input shapes for the create query vs update
  if (criterion.id < 0) {
    delete initialValues.input.ruleCriterion.id
    delete initialValues.input.id
  }
  // Form change needs to be debounced in case multiple fields get updated in quick succession
  // e.g. field/fieldLabel or value/valueLabel
  const onFormChange = _debounce(({ form, state }) => {
    if (
      (state.dirty && !state.submitting && !state.submitSucceeded) ||
      (state.submitSucceeded && state.dirtySinceLastSubmit)
    ) {
      form.submit()
    }
  }, 50)
  const onNewCriterion = () => {
    createNewCriterion({ sequence: criterion.sequence + 1 }, true, 'sequence')
  }

  // Ruleset fields search
  const searchFieldsByName = debounce(async (text) => {
    const { data: fieldsData } = await client.query({
      query: SEARCH_FIELDS_BY_NAME,
      variables: { name: text, rulesetId: Number(rulesetId) },
    })
    return fieldsData.allRulesetFields.nodes.map((x) => ({
      label: x.name,
      value: x.code,
    }))
  }, 200)

  // Delete / new row handlers
  const onDelete = () =>
    criterion.id >= 0
      ? deleteRuleCriterion({
          variables: { ruleCriterionId: criterion.id },
          update: (
            cache,
            {
              data: {
                deleteRuleCriterionById: { ruleCriterion },
              },
            }
          ) => {
            const rule = cache.readFragment({
              fragment: RULE_FRAGMENT,
              fragmentName: 'RuleParts',
              id: `Rule:${criterion.ruleId}`,
            })
            // Update the cache to exclude the deleted rule criterion
            cache.writeFragment({
              fragment: RULE_FRAGMENT,
              fragmentName: 'RuleParts',
              id: `Rule:${criterion.ruleId}`,
              data: {
                ...rule,
                ruleCriteriaByRuleId: {
                  ...rule.ruleCriteriaByRuleId,
                  nodes: [
                    ...rule.ruleCriteriaByRuleId.nodes.filter(
                      (x) => x.id !== ruleCriterion.id
                    ),
                  ],
                },
              },
            })
          },
        })
      : null

  return (
    <Frontier
      client={client}
      mutation={mutation}
      uiKit={uiKit}
      initialValues={initialValues}
      onFormChange={onFormChange}
      mutateProps={{
        update:
          criterion.id < 0
            ? (
                cache,
                {
                  data: {
                    createRuleCriterion: { ruleCriterion },
                  },
                }
              ) => {
                const rule = cache.readFragment({
                  fragment: RULE_FRAGMENT,
                  fragmentName: 'RuleParts',
                  id: `Rule:${criterion.ruleId}`,
                })
                // Update the cache to exclude the created rule criterion
                cache.writeFragment({
                  fragment: RULE_FRAGMENT,
                  fragmentName: 'RuleParts',
                  id: `Rule:${criterion.ruleId}`,
                  data: {
                    ...rule,
                    ruleCriteriaByRuleId: {
                      ...rule.ruleCriteriaByRuleId,
                      nodes: _sortBy(
                        [
                          ...rule.ruleCriteriaByRuleId.nodes.filter(
                            (x) => x.id !== criterion.id
                          ),
                          ruleCriterion,
                        ],
                        'sequence'
                      ),
                    },
                  },
                })
              }
            : null,
      }}
    >
      {({ modifiers, state }) => {
        return (
          <div className={`rt-tr a-mb-5 ${styles.criterionTableRow}`}>
            <div className={`rt-td ${styles.criterionTableSmallCell}`}>
              <span
                onClick={onNewCriterion}
                className="appkiticon icon-plus-outline a-mr-10"
              />
              <span
                onClick={onDelete}
                className="appkiticon icon-minus-outline"
              />
            </div>
            <div className={`rt-td ${styles.criterionTableGroupCell}`}>
              <InputNumber 
                min={0}
                max={10}
                value={_get(state, `values.${pathPrefix}.group`)}
                kind='arrow'
                integerOnly={true}
                onChange={_get(modifiers, `${pathPrefix}.group.change`)}
              />
            </div>
            <div
              className={`rt-td ${styles.criterionTableDropdownCell} ${styles.criterionTableAndOrCell}`}
            >
              <Select
                defaultValue={_get(state, `values.${pathPrefix}.andOr`)}
                onSelect={_get(modifiers, `${pathPrefix}.andOr.change`)}
                showSearchOnToggle={true}
              >
                <SelectOption value="and">And</SelectOption>
                <SelectOption value="or">Or</SelectOption>
              </Select>
            </div>
            <div className={`rt-td ${styles.criterionTableDropdownCell}`}>
              <ReactSelect
                onChange={(item) => 
                  _get(modifiers, `${pathPrefix}.fieldLabel.change`)(item.label) ||
                  _get(modifiers, `${pathPrefix}.field.change`)(item.value)
                }
                defaultValue={{
                  value: _get(state, `values.${pathPrefix}.field`),
                  label: _get(state, `values.${pathPrefix}.fieldLabel`),
                }}
                loadOptions={searchFieldsByName}
                classNamePrefix="rule-criterion-select"
                className={styles.criterionTableDropdownCell}
              />
            </div>
            <div className={`rt-td ${styles.criterionTableDropdownCell}`}>
              <Select
                defaultValue={_get(state, `values.${pathPrefix}.operator`)}
                onSelect={_get(modifiers, `${pathPrefix}.operator.change`)}
                showSearchOnToggle={true}
              >
                {operators.map((operator) => (
                  <SelectOption key={operator.id} value={operator.code}>
                    {operator.name}
                  </SelectOption>
                ))}
              </Select>
            </div>
            <div className={`rt-td ${styles.criterionTableDropdownCell}`}>
              <ReactSelect
                onChange={async (item) => 
                  _get(modifiers, `${pathPrefix}.valueLabel.change`)(item.label) ||
                  _get(modifiers, `${pathPrefix}.value.change`)(item.value)
                }
                defaultValue={{
                  value: _get(state, `values.${pathPrefix}.value`),
                  label: _get(state, `values.${pathPrefix}.valueLabel`),
                }}
                loadOptions={searchFieldsByName}
                classNamePrefix="rule-criterion-select"
                className={styles.criterionTableDropdownCell}
              />
            </div>
          </div>
        )
      }}
    </Frontier>
  )
}

RuleCriterionForm.propTypes = {
  criterion: PropTypes.object,
  rulesetId: PropTypes.number,
  createNewCriterion: PropTypes.func,
}
