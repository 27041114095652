import React, { Component } from 'react'
import { ApolloProvider } from 'react-apollo'
import Helmet from 'react-helmet'
import { apolloClient } from 'config/apollo'
import { withRouter } from 'react-router'

import Layout from 'components/core/components/layout'
import { Route, Switch } from 'react-router-dom'
import IDAMLogin from 'components/auth/containers/IDAMLogin'
import IDAMTokenProcessing from 'components/auth/containers/IDAMTokenProcessing'
import AuthorizedRoute from 'components/auth/containers/AuthorizedRoute'
import Dashboard from 'components/dashboard/containers/dashboard'
import ManageRuleset from 'components/rulesets/components/manageRuleset'
import EditRulesetFields from 'components/rulesets/components/editRulesetFields'
import AirtaxSandboxPage from 'components/airtax-rules-sandbox/components/airtaxSandboxPage'

class App extends Component {

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <Helmet
          defaultTitle="AirTax Automated Accountant"
          titleTemplate="%s - Airtax"
          meta={[
            { charset: 'utf-8' },
            {
              'http-equiv': 'X-UA-Compatible',
              content: 'IE=edge'
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1'
            }
          ]}
        />
        <Layout {...this.props} >
          <div className="example-item">
            <Switch>
              <Route exact path="/" component={IDAMLogin} />
              <Route exact path="/login" component={IDAMLogin} />
              <Route path="/signin" component={IDAMTokenProcessing} />
              <AuthorizedRoute exact path="/home" component={Dashboard} />
              <AuthorizedRoute exact path="/edit-ruleset-fields/:rulesetId" component={EditRulesetFields} />
              <AuthorizedRoute exact path="/manage-ruleset/:rulesetId" component={ManageRuleset} />
              <AuthorizedRoute exact path="/airtax-sandbox" component={AirtaxSandboxPage} />
            </Switch>
          </div>
        </Layout>
      </ApolloProvider>
    )
  }
}

export default withRouter(App)
