import React from 'react'
import { UIKit } from "@pwc/frontier-forms"
import { Input } from 'appkit-react'
import FieldWrapper from './fieldWrapper'

const uiKit = UIKit()
  .form((form, children) => (
    <form
      className={form.getState().submitting ? "ui form loading" : "ui form"}
      onSubmit={e => {
        e.preventDefault();
        form.submit();
      }}
    >
      <div>{children}</div>
      <br />
      <p>
        <input type="submit" value="Save" className="ui button" />
      </p>
    </form>
  ))
  .type("string", (path, required) => {
    return function FormInput(props) {
      return (
        <FieldWrapper required={required} type={"string"} path={path} {...props}>
          <Input
            type="text"
            onChange={value => {
              if (props.onChange) props.onChange(value)
              props.change(value.length > 0 ? value : null)
            }}
            value={typeof props.value === "undefined" ? "" : props.value}
            />
        </FieldWrapper>
      )
    };
  })

export default uiKit

  // .type("array", (path, required, children) => {
  //   return (props => {
  //     const values = (props.value || []);
  //     const list = props.list || [];
  //     const change = values => props.change(values);
  //     return (
  //       <FieldWrapper
  //         required={required}
  //         type={"string"}
  //         path={path}
  //         {...props}
  //       >
  //         <Table celled>
  //           <Table.Header>
  //             <Table.Row>
  //               <Table.HeaderCell>Selected</Table.HeaderCell>
  //             </Table.Row>
  //           </Table.Header>
  //
  //           <Table.Body>
  //             <Table.Row>
  //               <Table.Cell>
  //                 {values.map(val => (
  //                   <p>
  //                     {val.name}&nbsp;
  //                     <Button
  //                       color="red"
  //                       size="small"
  //                       compact
  //                       floated="right"
  //                       onClick={e => {
  //                         e.preventDefault();
  //                         change(values.filter(v => v.name !== val.name));
  //                       }}
  //                     >
  //                       remove
  //                     </Button>
  //                   </p>
  //                 ))}
  //               </Table.Cell>
  //             </Table.Row>
  //           </Table.Body>
  //         </Table>
  //
  //         <Table celled>
  //           <Table.Header>
  //             <Table.Row>
  //               <Table.HeaderCell>Available</Table.HeaderCell>
  //             </Table.Row>
  //           </Table.Header>
  //
  //           <Table.Body>
  //             <Table.Row>
  //               <Table.Cell>
  //                 {list
  //                   .filter(v => !values.find(vv => vv.name == v.name))
  //                   .map(val => (
  //                     <p>
  //                       {val.name}&nbsp;
  //                       <Button
  //                         size="small"
  //                         color="blue"
  //                         compact
  //                         floated="right"
  //                         onClick={e => {
  //                           e.preventDefault();
  //                           change(values.concat({ name: val.name }));
  //                         }}
  //                       >
  //                         add
  //                       </Button>
  //                     </p>
  //                   ))}
  //               </Table.Cell>
  //             </Table.Row>
  //           </Table.Body>
  //         </Table>
  //       </FieldWrapper>
  //     );
  //   };
  // })
  // .path(/rating/ as any, (path, type, required) => {
  //   return (props: UIKITFieldProps & { maxRating?: number }) => (
  //     <FieldWrapper required={required} type={type} path={path} {...props}>
  //       <Rating
  //         maxRating={props.maxRating || 5}
  //         onRate={(e, { rating }) => {
  //           props.change(rating);
  //         }}
  //         rating={typeof props.value === "undefined" ? 0 : props.value}
  //       />
  //     </FieldWrapper>
  //   );
  // })
  // .path(/comment/ as any, (path, type, required) => {
  //   return props => {
  //     return (
  //       <FieldWrapper required={required} type={type} path={path} {...props}>
  //         <TextArea
  //           value={typeof props.value === "undefined" ? "" : props.value}
  //           onChange={e => {
  //             const value = e.currentTarget.value;
  //             props.change(value.length > 0 ? value : null);
  //           }}
  //         />
  //       </FieldWrapper>
  //     );
  //   };
  // });
