import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Breadcrumb, BreadcrumbItem } from "appkit-react";
import styles from '../stylesheets/subheader.module.css';
import logo from 'appkit-react/style/images/component/pwc_logo_header_2x.png';
import config from '@pwc/frontend-env'

const subHeaderConfig = [
  ['zipline', 'Unprocessed'],
  ['zipline-step-1', 'OCR'],
  ['zipline-step-2', 'Predictions'],
  ['zipline-label', 'Training'],
  ['fraud', 'Fraud']
]

export default class Subheader extends React.Component {

  render() {
    const pathParts = this.props.location.pathname.split('/')
    const currentStepKey = pathParts[2]
    pathParts.splice(0, 3)
    const path = pathParts.join('/')
    const items = subHeaderConfig.map(config => {
      const isActive = currentStepKey === config[0]
      return {
        link: `/review/${config[0]}/${path}`,
        value: <span className={isActive ? 'a-breadcrumb-active' : ''}>{config[1]}</span>,
      }
    })

    if (!config('REACT_APP_TURN_ON_FRAUD_REVIEW')) items.pop()

    return (
      <div className='breadcrumb-story-container'>
        <Breadcrumb items={items} />
      </div>

    );
  }
}
