import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ClassName from 'classnames';
import {Search, List, ListItemText, Button, Select, SelectOption, Tooltip, Avatar} from 'appkit-react';
import logo from 'assets/images/logo.png';
import DocumentSubheader from './documentSubheader'
import ProjectSubheader from './projectSubheader'
import styles from '../stylesheets/menu.module.css'

const LINKS = [
  {
    label: 'Home',
    path: '/',
    icon: 'icon-duplicate-outline'
  },
  {
    label: 'Airtax Sandbox',
    path: '/airtax-sandbox',
  }
]

class Header extends React.Component {
  constructor() {
    super();
  }

  logoutTooltip = (currentAppUser) => (
    <div>
      <span className='a-list-title'>{`Welcome ${currentAppUser.firstName} ${currentAppUser.lastName}`}</span>
      <div className='a-log-out-btn'>
        <Button size='lg' onClick={this.props.handleLogout}>Sign Out</Button>
      </div>
    </div>
  )

  handleProjectChange = projectId => {
    this.props.setCurrentProjectId(projectId)
  }

  handleLogoClick = () => {
    this.props.history.push('/')
  }

  render() {
    const { data, projects, currentProjectId } = this.props
    const showDocumentSubheader = this.props.location.pathname.includes('review/zipline') || this.props.location.pathname.includes('review/fraud')
    const showProjectSubheader = this.props.location.pathname.includes('review')
    const currentAppUser = data.currentAppUser
    return (
      <div className={`${styles.header} normal-header-container`}>
        <nav className='a-header-wrapper justify-content-between flex-wrap'>
          <div className='a-brand-container'>
            <div className={`${styles.logo} a-pwc-logo-grid a-md`} onClick={this.handleLogoClick}></div>
            <div className='a-divider'></div>
            <span className='font-weight-medium'>AirTax Automated Accountant</span>
            {
              projects && projects.length > 0 &&
              <React.Fragment>
                <div className='a-divider-full'></div>
                <Select
                  placeholder="Select a project"
                  onSelect={this.handleProjectChange}
                  value={currentProjectId}
                  className='a-brand-select-container'
                  >
                  {
                    projects.map(project => (
                      <SelectOption
                        key={project.id}
                        value={project.id}
                        className='a-brand-item-container'
                        selectedDisplay={project.name}
                        >
                        <div className='a-option-text'>
                          {project.name}
                        </div>
                      </SelectOption>
                    ))
                  }
                </Select>
              </React.Fragment>
            }
            {
              LINKS.map(link => (
                <div key={link.path}>
                  <div className={`${styles.headerDivider} a-divider-full`}></div>
                  <Link to={link.path} className={`a-h6 ${styles.headerLink}`}>{link.label}</Link>
                </div>
              ))
            }
          </div>
          {currentAppUser &&
            <div className='a-actions-container'>
              <Tooltip
                content={this.logoutTooltip(currentAppUser)}
                trigger='click'
                refClassName='a-account-info'
                className='a-account-options'
                tooltipTheme='light'
                clickToClose
              >
                <Avatar label={`${currentAppUser.firstName[0]}${currentAppUser.lastName[0]}`} />
              </Tooltip>
            </div>
          }
          {showProjectSubheader &&
            <div className={`${styles.subheaderContainer}`}>
              <ProjectSubheader {...this.props}/>
            </div>
          }
          {showDocumentSubheader &&
            <div className={`${styles.subheaderContainer}`}>
              <DocumentSubheader {...this.props}/>
            </div>
          }
        </nav>
      </div>
    );
  }
}

Header.propTypes = {
  onCollapse: PropTypes.func
};
export default withRouter(Header);
