import gql from 'graphql-tag'

export const RULE_CRITERION_PARTS_FRAGMENT = gql`
fragment RuleCriterionParts on RuleCriterion {
  id
  andOr
  field
  fieldLabel
  operator
  value
  valueLabel
  ruleId
  sequence
  isDisabled
  group
}
`

export const RULE_FRAGMENT = gql`
  fragment RuleParts on Rule {
    id
    name
    description
    sequence
    isDisabled
    rulesetId
    ruleCriteriaByRuleId {
      nodes {
        ...RuleCriterionParts
      }
    }
  }
  ${RULE_CRITERION_PARTS_FRAGMENT}
`

export const RULESET_FIELD_FRAGMENT = gql`
  fragment RulesetFieldParts on RulesetField {
    id
    name
    rulesetId
    code
  }
`

export const GET_RULESET_BY_ID = gql`
  query GetRulesetById($id:Int!) {
    rulesetById(id:$id) {
      id
      name
      description
    }
  }
`

export const UPDATE_RULESET = gql`
  mutation UpdateRulesetById($input: UpdateRulesetByIdInput!) {
    updateRulesetById(input: $input) {
      ruleset {
        id
        name
        description
      }
    }
  }
`

export const CREATE_RULE = gql`
  mutation CreateRule($input:CreateRuleInput!) {
    createRule(input: $input) {
      rule {
        ...RuleParts
      }
    }
  }
  ${RULE_FRAGMENT}
`

export const COUNT_RULES_IN_RULESET = gql`
  query GetTotalRulesInRuleset($rulesetId:Int!) {
    allRules(condition:{ rulesetId: $rulesetId }) {
      totalCount
    }
  }
`

export const ALL_RULES_BY_RULSET = gql`
query RulesetRules($rulesetId:Int!) {
    allRules(condition:{ rulesetId: $rulesetId }) {
      nodes {
        ...RuleParts
      }
    }
  }
  ${RULE_FRAGMENT}
`

export const UPDATE_RULE = gql`
  mutation UpdateRuleById($input: UpdateRuleByIdInput!) {
    updateRuleById(input: $input) {
      rule {
        id
        name
      }
    }
  }
`

export const CREATE_RULE_CRITERION = gql`
  mutation CreateRuleCriterion($input: CreateRuleCriterionInput!) {
    createRuleCriterion(input: $input) {
      ruleCriterion {
        ...RuleCriterionParts
      }
    }
  }
  ${RULE_CRITERION_PARTS_FRAGMENT}
`

export const UPDATE_RULE_CRITERION = gql`
  mutation UpdateRuleCriterion($input: UpdateRuleCriterionByIdInput!) {
    updateRuleCriterionById(input: $input) {
      ruleCriterion {
        ...RuleCriterionParts
      }
    }
  }
  ${RULE_CRITERION_PARTS_FRAGMENT}
`

export const ALL_RULESET_FIELDS_BY_RULESET = gql`
  query AllRulesetFieldsByRuleset($rulesetId:Int!) {
    allRulesetFields(
      condition: { rulesetId: $rulesetId }
      orderBy: NAME_ASC
    ) {
      nodes {
        id
        name
        code
        rulesetId
      }
    }
  }
`

export const ALL_OPERATORS = gql`
  query AllOperators {
    allOperators(
      orderBy: NAME_ASC
    ) {
      nodes {
        id
        code
        name
      }
    }
  }
`

export const DELETE_RULE_CRITERION = gql`
  mutation DeleteRuleCriterion($ruleCriterionId:Int!) {
    deleteRuleCriterionById(input: { id: $ruleCriterionId}) {
      ruleCriterion {
        id
      }
    }
  }
`

export const ALL_RULESET_ACTION_TYPES = gql`
  query AllRulesetActionTypes($rulesetId:Int!) {
    allRulesetActionTypes(condition: { rulesetId: $rulesetId }) {
      nodes {
        id
        name
        shortName
      }
    }
  }
`

export const RULE_ACTION_FRAGMENT = gql`
  fragment RuleActionParts on RuleAction {
    id
    rulesetActionTypeByRulesetActionTypeId {
      name
      shortName
      id
    }
    ruleId
    metaData
  }
`

export const CREATE_RULE_ACTION = gql`
  mutation CreateRuleAction($input: CreateRuleActionInput!) {
    createRuleAction(input: $input) {
      ruleAction {
        ...RuleActionParts
      }
    }
  }
  ${RULE_ACTION_FRAGMENT}
`

export const ALL_RULE_ACTIONS_BY_RULE = gql`
  query AllRuleActions($ruleId:Int!) {
    allRuleActions(condition: { ruleId: $ruleId }) {
      nodes {
        ...RuleActionParts
      }
    }
  }
  ${RULE_ACTION_FRAGMENT}
`

export const UPDATE_RULE_ACTION = gql`
  mutation UpdateRuleAction($input: UpdateRuleActionByIdInput!) {
    updateRuleActionById(input: $input) {
      ruleAction {
        ...RuleActionParts
      }
    }
  }
  ${RULE_ACTION_FRAGMENT}
`

export const DELETE_RULE_ACTION = gql`
  mutation DeleteRuleActionById($ruleActionId:Int!) {
    deleteRuleActionById(input: { id: $ruleActionId }) {
      ruleAction {
        ...RuleActionParts
      }
    }
  }
  ${RULE_ACTION_FRAGMENT}
`

export const DELETE_RULE = gql`
  mutation DeleteRuleById($ruleId:Int!) {
    deleteRuleById(input: { id: $ruleId }) {
      rule {
        ...RuleParts
      }
    }
  }
  ${RULE_FRAGMENT}
`

export const DELETE_RULESET = gql`
  mutation DeleteRulesetById($rulesetId: Int!) {
    deleteRulesetById(input: { id: $rulesetId }) {
      ruleset {
        id
      }
    }
  }
`

export const SEARCH_FIELDS_BY_NAME = gql`
  query SearchFieldsByName($name: String!, $rulesetId: Int!) {
    allRulesetFields(
      first: 25
      filter: {
        name: { includesInsensitive: $name },
        rulesetId: { equalTo: $rulesetId }
    }) {
      nodes {
        id
        code
        name
      }
    }
  }
`

export const CREATE_RULESET_FIELD = gql`
  mutation CreateRulesetField($input: CreateRulesetFieldInput!) {
    createRulesetField(input: $input) {
      rulesetField {
        ...RulesetFieldParts
      }
    }
  }
  ${RULESET_FIELD_FRAGMENT}
`

export const UPDATE_RULESET_FIELD = gql`
  mutation UpdateRulesetField($input: UpdateRulesetFieldByIdInput!) {
    updateRulesetFieldById(input: $input) {
      rulesetField {
        ...RulesetFieldParts
      }
    }
  }
  ${RULESET_FIELD_FRAGMENT}
`

export const DELETE_RULESET_FIELD = gql`
  mutation DeleteRulesetFieldById($id: Int!) {
    deleteRulesetFieldById(input: { id: $id }) {
      rulesetField {
        id
      }
    }
  }
`
