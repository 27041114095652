import React from 'react'
import { Frontier } from '@pwc/frontier-forms'
import uiKit from 'components/ui-kit/uiKit'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Select } from 'appkit-react'
import { ALL_RULESET_ACTION_TYPES, CREATE_RULE_ACTION } from '../queries/rulesetQueries'
import { SelectOption } from 'appkit-react/lib/Select'
import _get from 'lodash/get'
import styles from '../stylesheets/rulesets.module.css'
import { updateCachePostRuleActionCreated } from '../queries/rulesetQueryHelpers'

export default function ActionForm({ rule }) {
  const client = useApolloClient()
  const { loading, data: actionsData } = useQuery(ALL_RULESET_ACTION_TYPES, {
    // hardcode rulesetId to be 1 here, because frontend user are NOT able to add new rule action types
    variables: { rulesetId: 1 },
  })

  if (loading) return <div />

  const actions = actionsData.allRulesetActionTypes.nodes

  return (
    <Frontier
      client={client}
      mutation={CREATE_RULE_ACTION}
      uiKit={uiKit}
      initialValues={{
        input: {
          ruleAction: {
            ruleId: rule.id,
            rulesetActionTypeId: _get(actions, '[0].id')
          },
        },
      }}
      mutateProps={{
        update: updateCachePostRuleActionCreated
      }}
    >
      {({ modifiers }) => {
        const submitForm = (e) => {
          modifiers[''].save()
          e.preventDefault()
        }
        return (
          <form onSubmit={submitForm} className={styles.actionForm}>
            <Select
              defaultValue={_get(actions, '[0].id')}
              onSelect={modifiers.input.ruleAction.rulesetActionTypeId.change}
            >
              {actions.map(action => (
                <SelectOption key={action.id} value={action.id}>
                  {action.name}
                </SelectOption>
              ))}
            </Select>
            <input
              type="submit"
              value="Add"
              className={`a-btn btn-gap a-btn-primary a-btn-sm float-right ${styles.actionFormButton}`}
            />
          </form>
        )
      }}
    </Frontier>
  )
}

ActionForm.propTypes = {
  rule: PropTypes.object
}
