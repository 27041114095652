/**
 * This container is response for enforcing IDAM login
 *
 * It does not display a login screen, it just automatically redirects to the
 * IDAM endpoint to kick off the login process
 */

import React from 'react'
import PropTypes from 'prop-types'
// import EasyTransition from 'react-easy-transition'
import config from '@pwc/frontend-env'
import { logout } from '../actions/authActions'
import QueryString from 'query-string'
import uuidv4 from 'uuid'
import { graphql } from 'react-apollo'
import { USER_PROFILE_QUERY } from 'components/user/queries/profileQueries'
import LoginPanelUpperInfo from './LoginPanelUpperInfo'
import styles from '../stylesheets/appkit.login.css'
import stylesModule from '../stylesheets/Login.module.css'

const ROOT_PATH = config('ROOT_PATH') || '/'

export class IDAMLogin extends React.Component { // eslint-disable-line
  static propTypes = {
    children: PropTypes.object,
    data: PropTypes.shape({
      currentUser: PropTypes.object
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    logout: PropTypes.func,
    router: PropTypes.object
  };

  componentWillMount() {
    if (this.props.data.currentAppUser && this.props.data.currentAppUser.id) {
      this.props.history.push('/home')
    } else {
      // Hide header
      document.querySelector('body').classList.add('scenario-login')

      // clear login tokens
      logout()
      // not logged in
      const IDAM_ENDPOINT = config('REACT_APP_IDAM_ENDPOINT')
      const IDAM_CLIENT_ID = config('REACT_APP_IDAM_CLIENT_ID')
      const query = QueryString.stringify({
        response_type: 'code',
        client_id: IDAM_CLIENT_ID,
        // redirect_uri: 'http://localhost:3000/signin',
        redirect_uri: window.location.protocol + '//' + window.location.host + ROOT_PATH + 'signin',
        scope: 'cloudEmail openid profile email country',
        state: uuidv4()
      })
      const idamUrl = IDAM_ENDPOINT + '?' + query
      window.location = idamUrl
    }
  }

  render() {
    return (
      <div className={stylesModule.loginPanel}>
        <div className="a-login-wrapper">
          <LoginPanelUpperInfo info="Loading..." />
        </div>
      </div>
    )
  }
}

export default graphql(USER_PROFILE_QUERY)(IDAMLogin)
