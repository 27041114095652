import React from 'react'
import { Frontier } from '@pwc/frontier-forms'
import uiKit from 'components/ui-kit/uiKit'
import { useApolloClient } from '@apollo/react-hooks'
import { CREATE_RULESET, DASHBOARD_RULESETS } from '../queries/dashboardQueries'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _snakeCase from 'lodash/snakeCase'
import _omit from 'lodash/omit'
import { UPDATE_RULESET } from 'components/rulesets/queries/rulesetQueries'
import { updateCacheAfterInsert } from 'lib/helpers/apolloHelpers'

export default function RulesetForm({ closeModal, record }) {
  const client = useApolloClient()

  let mutation = CREATE_RULESET
  let inputPath = 'input.ruleset'
  let initialValues = { input: {} }

  // This form is used for both updating and creating, so we need to change
  // parameters accordingly
  if (record) {
    mutation = UPDATE_RULESET
    initialValues.input.id = record.id
    initialValues.input.rulesetPatch = _omit(record, ['__typename'])
    inputPath = 'input.rulesetPatch'
  }

  return (
    <Frontier
      client={client}
      mutation={mutation}
      initialValues={initialValues}
      uiKit={uiKit}
      mutateProps={{
        update: !record ? updateCacheAfterInsert(
          DASHBOARD_RULESETS,
          'allRulesets',
          'createRuleset.ruleset'
        ) : null
      }}
    >
      {({ kit, modifiers }) => {
        const submitForm = e => {
          if (closeModal) closeModal()
          modifiers[''].save()
          e.preventDefault()
        }
        const onNameChange = (name) => _get(modifiers, `${inputPath}.shortName`).change(_snakeCase(name))
        return (
          <form onSubmit={submitForm}>
            {_get(kit, `${inputPath}.name`)({ label: 'Name', onChange: onNameChange })}
            {_get(kit, `${inputPath}.description`)({ label: 'Description' })}
            <input
              type="submit"
              value="Save"
              className="a-btn btn-gap a-btn-primary a-btn-md"
            />
          </form>
        )
      }}
    </Frontier>
  )
}

RulesetForm.propTypes = {
  closeModal: PropTypes.func,
  record: PropTypes.object,
}
