import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextArea } from 'appkit-react'
import { Frontier } from '@pwc/frontier-forms'
import { useApolloClient, useMutation } from 'react-apollo'
import { UPDATE_RULE_ACTION, DELETE_RULE_ACTION } from '../../queries/rulesetQueries'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import { Button } from 'appkit-react/lib/Button'
import styles from '../../stylesheets/rulesets.module.css'
import { updateCachePostRuleActionDelete } from 'components/rulesets/queries/rulesetQueryHelpers'

export default function ReviewNeededActionForm({ action }) {
  const client = useApolloClient()
  const [deleteRuleAction] = useMutation(DELETE_RULE_ACTION)
  const [message, setMessage] = useState(_get(action, 'metaData.message', ''))
  const onFormChange = ({ form, state }) => {
    if (state.dirty && !state.submitting) {
      form.submit()
    }
  }

  // Delete rule action
  const onDelete = () =>
    deleteRuleAction({
      variables: { ruleActionId: action.id },
      update: updateCachePostRuleActionDelete
    })

  return (
    <Frontier
      client={client}
      mutation={UPDATE_RULE_ACTION}
      onFormChange={onFormChange}
      initialValues={{
        input: {
          id: action.id,
          ruleActionPatch: _omit(action, [
            'rulesetActionTypeByRulesetActionTypeId',
            '__typename',
          ]),
        },
      }}
    >
      {({ modifiers, state }) => {
        const onChange = () => {
          if (
            message !==
            _get(state, 'values.input.ruleActionPatch.metaData.message', '')
          )
            modifiers.input.ruleActionPatch.metaData.change({ message })
        }
        return (
          <div className={styles.reviewNeededAction}>
            <Button
              kind="secondary"
              onClick={onDelete}
              className={styles.reviewNeededDeleteButton}
            >
              <span className="appkiticon icon-delete-fill" />
            </Button>
            <TextArea
              label="Review Message"
              placeholder="Enter the reason this has been flagged for review"
              className="fix-textarea"
              value={message}
              onChange={setMessage}
              onBlur={onChange}
            />
          </div>
        )
      }}
    </Frontier>
  )
}

ReviewNeededActionForm.propTypes = {
  action: PropTypes.object,
}
