import React, { useEffect } from 'react'
import styles from '../stylesheets/rulesets.module.css'
import PropTypes from 'prop-types'
import { RULE_FRAGMENT } from '../queries/rulesetQueries'
import { addEmptyRecordToCache } from 'lib/helpers/apolloHelpers'
import { useApolloClient } from '@apollo/react-hooks'
import RuleCriterionForm from './ruleCriterionForm'

export default function RuleCriteriaTable({ rule, criteria, className }) {
  const client = useApolloClient()
  const createNewCriterion = (criterion = {}, forceCreate = false, sortKey = null) => (
    addEmptyRecordToCache(
      RULE_FRAGMENT,
      `Rule:${rule.id}`,
      'ruleCriteriaByRuleId.nodes',
      client,
      {
        ruleId: rule.id,
        __typename: 'RuleCriterion',
        isDisabled: false,
        group: 0,
        andOr: 'and',
        field: '',
        fieldLabel: '',
        operator: '',
        value: '',
        valueLabel: '',
        sequence: criteria.length + 1,
        ...criterion
      },
      'RuleParts',
      forceCreate,
      sortKey
    )
  )
  useEffect(
    createNewCriterion,
    [criteria] // only invoke effect once
  )

  return (
    <div
      className={`ReactTable rt-table ${className} ${styles.conditionsTable}`}
    >
      <div className="rt-thead">
        <div className="rt-tr">
          <div className={`rt-th ${styles.criterionTableSmallCell}`}></div>
          <div className={`rt-th ${styles.criterionTableAndOrCell}`}>
            Group
          </div>
          <div className={`rt-th ${styles.criterionTableAndOrCell}`}>
            And/Or
          </div>
          <div className="rt-th">Field</div>
          <div className="rt-th">Operator</div>
          <div className="rt-th">Value</div>
        </div>
      </div>
      <div className={`rt-tbody ${styles.criterionTableBody}`}>
        {criteria.map((criterion) => (
          <RuleCriterionForm
            key={criterion.id}
            createNewCriterion={createNewCriterion}
            criterion={criterion}
            rulesetId={rule.rulesetId}
          />
        ))}
      </div>
    </div>
  )
}

RuleCriteriaTable.propTypes = {
  criteria: PropTypes.array,
  rule: PropTypes.object,
  className: PropTypes.string,
}
