import React from "react";
import styles from "../stylesheets/loadingPanel.module.scss";

export default function LoadingPanel() {
  return (
    <div className={`d-inline-block text-center ${styles.loadingPanel}`}>
      <div className="a-loading a-primary" />
      <div className="a-mt-10 a-h6 a-text-black">Loading, please wait</div>
    </div>
  );
}
