import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'
import {
  GET_RULESET_BY_ID,
  ALL_RULES_BY_RULSET,
  DELETE_RULESET,
} from '../queries/rulesetQueries'
import LoadingPanel from 'components/common/components/loadingPanel'
import CreateRecordButton from 'components/common/components/createRecordButton'
import styles from '../stylesheets/rulesets.module.css'
import RuleForm from './ruleForm'
import RulePanel from './rulePanel'
import RulesetForm from 'components/dashboard/components/rulesetForm'
import DeleteRecordButton from 'components/common/components/deleteRecordButton'
import { updateCacheAfterDelete } from 'lib/helpers/apolloHelpers'
import { DASHBOARD_RULESETS } from 'components/dashboard/queries/dashboardQueries'

export default function ManageRulesets() {
  const { rulesetId } = useParams()
  const history = useHistory()
  const [deleteRulset] = useMutation(DELETE_RULESET)
  const { loading: rulesetLoading, data: rulesetData } = useQuery(
    GET_RULESET_BY_ID,
    {
      variables: { id: Number(rulesetId) },
    }
  )
  const { loading: rulesLoading, data: rulesData } = useQuery(
    ALL_RULES_BY_RULSET,
    {
      variables: { rulesetId: Number(rulesetId) },
    }
  )

  if (rulesetLoading || rulesLoading) return <LoadingPanel />

  // Extract data
  const ruleset = rulesetData.rulesetById
  const rules = rulesData.allRules.nodes

  // Delete handler
  const onDelete = async () => {
    await deleteRulset({
      variables: { rulesetId: Number(rulesetId) },
      update: updateCacheAfterDelete(
        DASHBOARD_RULESETS,
        'allRulesets',
        'deleteRulesetById.ruleset'
      ),
    })
    // Leave the ruleset page now that we have deleted the ruleset
    history.push('/')
  }

  return (
    <React.Fragment>
      <h5>{`Manage Ruleset - Ruleset ${rulesetId}`}</h5>
      <div className="a-h6 a-mb-20">
        {ruleset.name}{' '}
        <CreateRecordButton
          title="Edit Ruleset"
          icon="icon-edit-outline"
          record={ruleset}
          Form={RulesetForm}
        />
      </div>
      <CreateRecordButton
        className={styles.newRuleButton}
        buttonText="New Rule"
        size="md"
        title="Create Rule"
        Form={RuleForm}
      />
      <DeleteRecordButton size="md" className="float-right mr-10" buttonText="Delete Ruleset" onDelete={onDelete} />

      <div className="contact-panel d-table w-100">
        <div className="d-flex flex-wrap flex-sm-nowrap">
          <div className="a-mb-20 w-100">
            {rules.map((rule) => (
              <RulePanel rule={rule} key={rule.id} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
