/**
 * This module contains a function for wrapping components with a higher level
 * component that enforces auth
 *
 * @type {Object}
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Link, Route } from 'react-router-dom'
import { Panel, Badge, Button } from 'appkit-react'
import { graphql } from 'react-apollo'
import { USER_PROFILE_QUERY } from 'components/user/queries/profileQueries'
import { isLoading } from 'lib/helpers/utilsHelpers'
import LoginPanelUpperInfo from './LoginPanelUpperInfo'
import stylesModule from '../stylesheets/Login.module.css'

class AuthorizedRoute extends React.Component {
  static propTypes = {
    component: PropTypes.func,
    data: PropTypes.shape({
      currentAppUser: PropTypes.object,
      loading: PropTypes.bool
    })
  }

  static contextTypes = {
    router: PropTypes.object,
  }

  checkAuth(props) {
    const { currentAppUser, loading } = props.data
    if (!loading && (!currentAppUser || !currentAppUser.id)) {
      return false
    }
    return true
  }

  render() {
    const { component: Component, ...rest } = this.props

    if (isLoading(this.props.data)) return (<span>Loading</span>)

    if (!this.checkAuth(this.props)) {
      document.querySelector('body').classList.add('scenario-login')
      return (
        <div className={stylesModule.loginPanel}>
          <div className="a-login-wrapper">
            <LoginPanelUpperInfo info="Welcome back!" />
            <Button size="lg" onClick={()=>{window.location.href='/'}}>Click here to login</Button>
          </div>
        </div>
      )
    }

    return (
      <Route {...rest} render={(props2) => (
        <Component {...props2} workflow={this.props.workflow} pipelineId={this.props.pipelineId} user={this.props.data.currentAppUser} />
      )} />
    )
  }
}

export default graphql(USER_PROFILE_QUERY, {
  options: { errorPolicy: 'none', fetchPolicy: 'network-only' }
})(AuthorizedRoute)
