import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
// unregister()

// Initialize store
const mountApp = document.getElementById('root')

const render = (Component) => {
  ReactDOM.render(
    <BrowserRouter basename={'/'}>
      <Component />
    </BrowserRouter>,
    mountApp
  )
}

render(App)
