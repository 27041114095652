import React, { Component } from 'react'
import styles from '../stylesheets/dashboard.module.css'
import WelcomePanel from './welcomePanel'
import RulesetPanel from './rulesetPanel'

export default class Dashboard extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-4">
          <WelcomePanel />
        </div>
        <div className="col-lg-8">
          <RulesetPanel />
        </div>
      </div>
    )
  }
}
