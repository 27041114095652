import React from "react";
import { Modal, Button, ModalHeader, ModalBody } from "appkit-react"
import PropTypes from 'prop-types'

/**
 * Modal that uses frontier forms to display a create record form
 * @method      CreateRecordModal
 * @constructor
 */
export default function CreateRecordModal({
  isModalOpen,
  handleCloseModal,
  title,
  Form,
  record
}) {
  return (
    <Modal
      className="action-modal form-modal"
      visible={isModalOpen}
      onCancel={handleCloseModal}
    >
      <ModalHeader style={{ paddingTop: 20 }}>
        <div className="modal-title">{title}</div>
      </ModalHeader>
      <ModalBody style={{ marginBottom: 0 }}>
        <div className="row">
          <div className="col-12">
            <Form record={record} closeModal={handleCloseModal} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

CreateRecordModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  mutation: PropTypes.object,
  title: PropTypes.string,
  Form: PropTypes.func,
  record: PropTypes.object
}
