/**
 * Module containing helpers for common apollo operations
 */

import _get from 'lodash/get'
import _set from 'lodash/set'
import _sortBy from 'lodash/sortBy'

/**
 * Adds an empty record to the cached result set for a query
 * This is useful where we want to allow the user to add a new row to a list/table
 * but not have it persisted to the backend until a change is actually made
 * @param {object} fragment  GQL fragment
 * @param {string} fragmentName  Fragment name
 * @param {number} id Object id
 * @param {string} dataPath  Path to data to insert new record at within GQL fragment
 * @param {object} apolloClient Apollo client instance
 */
export function addEmptyRecordToCache(
  fragment,
  id,
  dataPath,
  apolloClient,
  newRecord = {},
  fragmentName = null,
  forceCreate = false,
  sortKey = null
) {
  // Check if there is already an empty record
  const data = apolloClient.readFragment({
    id,
    fragment,
    fragmentName,
  })
  const records = _get(data, dataPath)

  // Exit if there is already an empty record, we only need one
  if (records && records.find((x) => x.id < 0) && !forceCreate) return

  // No empty record, so need to create one
  _set(data, dataPath, _sortBy([
    ...records,
    {
      id: new Date().valueOf() * -1, // use linux timestamp as tmp id
      ...newRecord,
    },
  ], sortKey))
  apolloClient.writeFragment({
    fragment,
    fragmentName,
    id,
    data,
  })
}

/**
 * Factory method for creating a function that can be passed to as the update attribute to the 
 * 'mutate' call to update the cache post inserting a record
 * @param {object} query GraphQL query for list of records of the inserted type
 * @param {string} queryName Name of the query - e.g. allRules
 * @param {string} recordPath Name of the attribute in the mututation result - e.g. createRule.rule
 */
export function updateCacheAfterInsert(query, queryName, recordPath, variables = {}) {
  return function postInsertCacheUpdate(cache, { data }) {
    const records = cache.readQuery({
      query,
      variables
    })[queryName]
    cache.writeQuery({
      query,
      variables,
      data: {
        [queryName]: {
          ...records,
          nodes: [...records.nodes, _get(data, recordPath)]
        }
      }
    })
  }
}

/**
 * Factory method for creating a function that can be passed to as the update attribute to the 
 * 'mutate' call to update the cache post deleting a record
 * @param {object} query GraphQL query for list of records of the inserted type
 * @param {string} queryName Name of the query - e.g. allRules
 * @param {string} recordPath Name of the attribute in the mututation result - e.g. deleteRuleById.rule
 */
export function updateCacheAfterDelete(query, queryName, recordPath, variables = {}) {
  return function postDeleteCacheUpdate(cache, { data }) {
    const records = cache.readQuery({
      query,
      variables
    })[queryName]
    const deletedRecord = _get(data, recordPath)
    cache.writeQuery({
      query,
      variables,
      data: {
        [queryName]: {
          ...records,
          nodes: records.nodes.filter(x => x.id !== deletedRecord.id)
        }
      }
    })
  }
}
