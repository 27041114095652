import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/bootstrap-theme.css';
import 'appkit-react/style/appkit-react.default.css';
import ClassName from 'classnames';
import Header from '../containers/header'
import Footer from './footer'
import Menu from './menu'

import '../stylesheets/layout.css';
import '../stylesheets/misc.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultCollapseMenu: true
    };
  }
  handleClick = () => {
    const { collapsed, defaultCollapseMenu } = this.state;
    if (collapsed === undefined) {
      this.setState({
        collapsed: !defaultCollapseMenu
      });
    } else {
      this.setState({
        collapsed: !collapsed
      });
    }
  };


  render() {
    const { collapsed } = this.state;
    const contentClass = ClassName('content', {
      [`pop-right`]: collapsed === true,
      [`shrink-right`]: collapsed === false
    });
    const styles = {
      opendContentPadding: {
        paddingTop: '1.25rem',
        paddingRight: '.625rem'
      },
      closedContentPadding: {
        paddingTop: 0,
        paddingRight: 0
      }
    };
    return (
      <div className="nav-template template-theme-two">
        <div
          className={contentClass}
          style={
            collapsed === true
              ? styles.closedContentPadding
              : collapsed === false
                ? styles.opendContentPadding
                : null
          }
        >
          <div className="right-content">
            <Header onCollapse={this.handleClick} />
            {this.props.children}
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
