import gql from 'graphql-tag'

export const AIRTAX_RETURNS = gql`
  query AllAirtaxReturns {
    allTaxReturns(first: 50) {
      nodes {
        year
        id
      }
    }
  }
`

export const AIRTAX_RETURN_BY_ID = gql`
  query AirtaxReturnData($taxReturnId: Int!) {
    taxReturnById(id: $taxReturnId) {
      lootValues
      id
    }
  }
`

export const CREATE_AIRTAX_RETURN_REVIEW = gql`
  mutation CreateAirtaxReturnReview($taxReturnId: Int!, $taxReturnData: JSON) {
    createTaxReturnReview(input: {
      taxReturnReview: {
        taxReturnId: $taxReturnId,
        taxReturnData: $taxReturnData
      }
    }) {
      taxReturnReview {
        id
        taxReturnId
        ruleResults
        createdAt
      }
    }
  }
`

export const ALL_AIRTAX_RETURNS = gql`
  query AllTaxReturnReview {
    allTaxReturnReviews(last:20) {
      nodes {
        id
        taxReturnId
        ruleResults
        createdAt
      }
    }
  }
`