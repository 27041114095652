import React from 'react'
import { Panel, Button } from 'appkit-react'

const ErrorPanel = ({ children }) => (
  <Panel
    title="Sorry, something went wrong"
    renderRight={() => {
      return (
        <Button size="sm" gray>ERROR</Button>
      );
    }}
  >
  {children}
  </Panel>
)

export default ErrorPanel
