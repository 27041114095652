/**
 * This IDAM container should be invoked when the OAuth server redirects back
 * to this app after logging in via the oauth provider (e.g. PwC IDAM)
 */

import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import QueryString from 'query-string'
import { authenticateWithIDAM } from '../actions/authActions'
import { graphql } from 'react-apollo'
import { USER_PROFILE_QUERY } from 'components/user/queries/profileQueries'
import { Panel } from 'appkit-react'
import IDAMstyles from '../stylesheets/IDAMTokenProcessing.module.scss'
import ErrorPanel from 'components/common/components/errorPanel'
import LoginPanelUpperInfo from './LoginPanelUpperInfo'
import stylesModule from '../stylesheets/Login.module.css'

class IDAMTokenProcessing extends PureComponent { // eslint-disable-line
  static propTypes = {
    children: PropTypes.node,
    data: PropTypes.shape({
      currentAppUser: PropTypes.object
    }),
    isAuthenticated: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }

  constructor(props) {
    super(props)
    this.apolloClient = props.client
    this.state = {}
  }

  componentWillMount() {
    const self = this
    if (this.props.data.currentAppUser && this.props.currentAppUser.id) {
      // Check if the user is already logged in (and should not be here)
      self.props.history.push('/home')
    } else {
      document.querySelector('body').classList.add('scenario-login')
      // received IDAM token
      const params = QueryString.parse(window.location.search)
      // Invoke the action to verify the idam token.
      authenticateWithIDAM(params.code)
      .then(() => {
        window.location.replace('/home')
      })
      .catch(errorMessage => {
        this.setState({error: `${errorMessage}`})
      })
    }
  }

  render() {
    return (
      <div>
        {this.state.error &&
          <ErrorPanel
            statusMessage={this.state.error}
          >
            <Link to="/login">Click here to try again</Link>
          </ErrorPanel>
        }
        {!this.state.error &&
          <div className={stylesModule.loginPanel}>
            <div className="a-login-wrapper">
              <LoginPanelUpperInfo info="You are being logged in. Please wait." />
            </div>
          </div>
        }
      </div>
    )
  }
}

// withApollo
export default graphql(USER_PROFILE_QUERY)(IDAMTokenProcessing)
