import React from 'react'
import { Table } from 'appkit-react/lib/Table'
import { useQuery } from 'react-apollo'
import { ALL_AIRTAX_RETURNS } from '../queries/airtaxSandboxQueries'
import ReactJson from 'react-json-view'

const COLUMNS = [
  { accessor: 'taxReturnId', Header: 'Tax Return ID' },
  {
    accessor: 'ruleResults',
    Header: 'Results',
    Cell: function RuleResults({row}) {
      return <ReactJson src={row.ruleResults} />
    },
  },
  { accessor: 'createdAt', Header: 'Created At' },
]

export default function AirtaxReturnsTable() {
  const { data: returnsData, loading } = useQuery(ALL_AIRTAX_RETURNS)

  if (loading) return <div />

  return (
    <Table
      columns={COLUMNS}
      showPagination={false}
      data={returnsData.allTaxReturnReviews.nodes}
    />
  )
}
