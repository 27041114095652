import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'
import { Table } from 'appkit-react/lib/Table'
// graphql imports
import { 
  ALL_RULESET_FIELDS_BY_RULESET,
  DELETE_RULESET_FIELD,
} from '../queries/rulesetQueries'
import { updateCacheAfterDelete } from 'lib/helpers/apolloHelpers'
// components imports
import LoadingPanel from 'components/common/components/loadingPanel'
import CreateRecordButton from 'components/common/components/createRecordButton'
import DeleteRecordButton from 'components/common/components/deleteRecordButton'
import RulesetFieldForm from './rulesetFieldForm'
// css imports
import styles from '../stylesheets/rulesets.module.css'

export default function EditRulesetFields() {
  const history = useHistory()
  const { rulesetId } = useParams()
  const [ deleteRulesetField ] = useMutation(DELETE_RULESET_FIELD)
  const { loading, data: rulesetFieldData } = useQuery(
    ALL_RULESET_FIELDS_BY_RULESET, 
    {
      variables: { rulesetId: Number(rulesetId) }
    })
  const manageRuleset = id => history.push(`/manage-ruleset/${id}`)
  
  if (loading) return <LoadingPanel />

  const allRulesetFields = rulesetFieldData.allRulesetFields.nodes

  const onDelete = async(id) => {
    await deleteRulesetField({
      variables: { id },
      update: updateCacheAfterDelete(
        ALL_RULESET_FIELDS_BY_RULESET,
        'allRulesetFields',
        'deleteRulesetFieldById.rulesetField',
        { rulesetId: Number(rulesetId) }
      )
    })
  }
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 50 // px
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Code',
      accessor: 'code',
    },
    {
      Header: 'Action',
      accessor: 'id',
      maxWidth: 150,
      Cell: ({ row }) => {
        return (
          <React.Fragment>
          <CreateRecordButton
            className="a-mr-5"
            size="sm"
            buttonText="Edit"
            Form={RulesetFieldForm}
            record={row._original}
            title="Edit Ruleset Field"
          />
          <DeleteRecordButton buttonText="Delete" onDelete={() => onDelete(row._original.id)} />
        </React.Fragment>
        )
      }
    },
  ]
  return (
    <React.Fragment>
      <h5>{`Manage Ruleset fields - Ruleset ${rulesetId}`}</h5>
      <CreateRecordButton
        className={styles.newRuleButton}
        buttonText="New ruleset field"
        size="md"
        title="Create Ruleset Field"
        Form={RulesetFieldForm}
      />

      <div className="contact-panel d-table w-100">
        <div className="d-flex flex-wrap flex-sm-nowrap">
          <div className="a-mb-20 w-100">
            <div className={`a-panel ${styles.rulesetFieldTable}`}>
              <Table
                data={allRulesetFields}
                columns={columns}
                showPagination={true}
                defaultPageSize={20}
                sortable={false}
                resizable={true}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
