import React from "react"
import { Badge } from 'appkit-react'

const LoginPanelUpperInfo = ({ info = '' }) => {
  return (
    <div className="a-login-upper-info center-align">
      <div className="a-login-logo a-pwc-logo-grid" />
      <div className="a-login-title">AirTax Automated Accountant</div>
      <div className="a-login-info">{ info }</div>
    </div>
  );
};

export default LoginPanelUpperInfo
