import { callApi } from 'lib/helpers/apiHelpers'
// import { getLocalStorage } from 'lib/helpers/storageHelpers'

export const RECEIVE_AUTH = 'RECEIVE_AUTH'
export const RECEIVE_AUTH_ERROR = 'RECEIVE_AUTH_ERROR'
export const LOGOUT = 'LOGOUT'
export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT'
export const CLEAR_LOGIN_REDIRECT = 'CLEAR_LOGIN_REDIRECT'

export const receiveAuthError = (payload) => {
  return {
    type: RECEIVE_AUTH_ERROR,
    payload,
  }
}


// clear all cookies in all paths and all variants of the domain (www.mydomain.com, mydomain.com etc):
const clearCookies = () => {
  const cookies = document.cookie.split('; ')
  for (let c = 0; c < cookies.length; c++) {
    let d = window.location.hostname.split('.')
    while (d.length > 0) {
      const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path='
      const p = window.location.pathname.split('/')
      document.cookie = cookieBase + '/'
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/')
        p.pop()
      }
      d.shift()
    }
  }
}
export const logout = async () => {
  return await callApi('auth/logout')
}


/**
 * Exchanges an IDAM token for an app-issued JWT
 * @method authenticateWithIDAM
 * @param  {string}             idamToken oauth token provided by the IDAM server
 */
export function authenticateWithIDAM(idamToken) {
  return callApi('auth', {
    method: 'post',
    params: { idamToken }
  })
  .then(result => {
    // getLocalStorage().set('client_id', result.client_id)
    return result
  })
  .catch(error => {
    console.error('LOGIN/AUTH ERROR', error)
    return error.text().then(res => { throw Error(res) })
  })
}
