import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import { ALL_RULE_ACTIONS_BY_RULE } from '../queries/rulesetQueries'
import LoadingPanel from 'components/common/components/loadingPanel'
import ReviewNeededActionForm from './actionForms/reviewNeededActionForm'

// comment out the following codes
// we currently only have one action form so there is no need to get it dynamically
// const ACTION_COMPONENTS = {
//   review_needed: ReviewNeededActionForm
// }

export default function RuleActionsList({
  rule
}) {
  const { loading: actionsLoading, data: actionsData } = useQuery( ALL_RULE_ACTIONS_BY_RULE, {
    variables: { ruleId: rule.id }
  })

  if (actionsLoading) return (<LoadingPanel />)

  const actions = actionsData.allRuleActions.nodes

  return (
    <React.Fragment>
      {actions.map(action => {
        const Component = ReviewNeededActionForm
        
        if (!Component) throw Error(`Cannot find action component for ${action.shortName}`)

        return (<Component key={action.id} action={action} />)
      })}
    </React.Fragment>
  )
}

RuleActionsList.propTypes = {
  rule: PropTypes.object
}
