import React from 'react'
import PropTypes from 'prop-types'
import styles from '../stylesheets/rulesets.module.css'
import ActionForm from './actionForm'
import RuleActionsList from './ruleActionsList'

export function RuleActionsPanel({ rule }) {

  return (
    <div className={styles.actionPanel}>
      <div className={styles.actionLabel}>Action</div>
      <ActionForm rule={rule} />
      <RuleActionsList rule={rule } />
    </div>
  )
}

RuleActionsPanel.propTypes = {
  rule: PropTypes.object
}