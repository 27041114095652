import React, { useState } from 'react'
import AirtaxReturnsDropdown from './airtaxReturnsDropdown'
import ReactJson from 'react-json-view'
import { Button } from 'appkit-react/lib/Button'
import { useMutation } from 'react-apollo'
import { CREATE_AIRTAX_RETURN_REVIEW, ALL_AIRTAX_RETURNS } from '../queries/airtaxSandboxQueries'
import { updateCacheAfterInsert } from 'lib/helpers/apolloHelpers'
import AirtaxReturnsTable from './airtaxReturnsTable'

export default function AirtaxSandboxPage() {
  const [ airtaxReturn, setAirtaxReturn ] = useState({})
  const [createTaxReturnReview] = useMutation(CREATE_AIRTAX_RETURN_REVIEW, {
    variables: { taxReturnId: airtaxReturn.id, taxReturnData: airtaxReturn.lootValues },
    update: updateCacheAfterInsert(
      ALL_AIRTAX_RETURNS,
      'allTaxReturnReviews',
      'createTaxReturnReview.taxReturnReview'
    )
  })

  return (
    <React.Fragment>
      <div className="a-h5">Airtax Rules Sandbox</div>
      <div className="a-h6 a-mb-20">
        You can use this page to test rules against Airtax data and simulate
        rules running against a completed tax return
      </div>
      <AirtaxReturnsTable />
      <br/>
      <div className="row">
        <div className="col-lg-8 mt-20">
          <AirtaxReturnsDropdown onChange={setAirtaxReturn} />
        </div>
        <div className="col-lg-4">
          <Button size="lg" onClick={createTaxReturnReview}>Trigger review</Button>
        </div>
      </div>
      <div className="row">
        <ReactJson src={airtaxReturn.lootValues} style={{maxHeight: '300px'}} />
      </div>
    </React.Fragment>
  )
}
