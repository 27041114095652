/**
 * This module contains the configured apollo client, including local state
 * @type {[type]}
 */

import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { authLink, errorHandler } from 'lib/apolloAuthMiddleware'
import config from '@pwc/frontend-env'
import initialLocalState from './apollo-local-state'

/** Apollo **/
const cache = new InMemoryCache()

/** Apollo local state **/
cache.writeData(initialLocalState)

export const apolloClient = new ApolloClient({
  cache,
  // authlink injects the authorization header
  link: ApolloLink.from([
    errorHandler,
    authLink.concat(createHttpLink({ uri: config('REACT_APP_BACKEND_URL') + '/graphql'}))
  ]),
})
