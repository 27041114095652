import React from 'react'
import ReactSelect from 'react-select/async'
import PropTypes from 'prop-types'
import { apolloClient } from '../lib/airtax-apollo-client'
import debounce from 'debounce-promise'
import { AIRTAX_RETURNS, AIRTAX_RETURN_BY_ID } from '../queries/airtaxSandboxQueries'

export default function AirtaxReturnsDropdown({
  onChange
}) {
  const searchReturns = debounce(async (text) => {
    const  { data: { allTaxReturns: { nodes: taxReturns }} } = await apolloClient.query({
      query: AIRTAX_RETURNS
    })

    return taxReturns.map(x => ({
      value: x.id,
      label: `${x.id} - ${x.year}`
    }))
  }, 250)
  const onSelectReturn = async item => {
    // Fetch the json
    const { data: { taxReturnById: taxReturn } } = await apolloClient.query({
      query: AIRTAX_RETURN_BY_ID,
      variables: { taxReturnId: item.value }
    })

    onChange(taxReturn)
  }

  return (
    <ReactSelect
      loadOptions={searchReturns}
      onChange={onSelectReturn}
    />
  )
}

AirtaxReturnsDropdown.propTypes = {
  onChange: PropTypes.func
}