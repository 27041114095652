import React from 'react'
import { Frontier } from '@pwc/frontier-forms'
import uiKit from 'components/ui-kit/uiKit'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { CREATE_RULE, UPDATE_RULE } from '../queries/rulesetQueries'
import PropTypes from 'prop-types'
import _snakeCase from 'lodash/snakeCase'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import {
  COUNT_RULES_IN_RULESET,
  ALL_RULES_BY_RULSET,
} from '../queries/rulesetQueries'
import { useParams } from 'react-router-dom'
import { updateCacheAfterInsert } from 'lib/helpers/apolloHelpers'

export default function RuleForm({ closeModal, record }) {
  const client = useApolloClient()
  const { rulesetId } = useParams()
  const { loading, data: rulesetCountData } = useQuery(COUNT_RULES_IN_RULESET, {
    variables: { rulesetId: Number(rulesetId) },
  })

  if (loading) return <div />

  let initialValues = {
    input: {
      rule: {
        sequence: rulesetCountData.allRules.totalCount,
        rulesetId: Number(rulesetId),
        shortName: '',
        isDisabled: false,
      },
    },
  }
  let mutation = CREATE_RULE
  let inputPath = 'input.rule'

  // This form is used for both updating and creating, so we need to change
  // parameters accordingly
  if (record) {
    mutation = UPDATE_RULE
    initialValues.input.id = record.id
    initialValues.input.rulePatch = _omit(record, [ 'ruleCriteriaByRuleId', '__typename' ])
    inputPath = 'input.rulePatch'
    delete initialValues.input.rule
  }

  return (
    <Frontier
      client={client}
      mutation={mutation}
      uiKit={uiKit}
      initialValues={initialValues}
      mutateProps={{
        // Update the apollo cache after we insert the new rule
        update: !record ? updateCacheAfterInsert(
          ALL_RULES_BY_RULSET,
          'allRules',
          'createRule.rule',
          { rulesetId: Number(rulesetId) }
        ) : null,
      }}
    >
      {({ kit, modifiers, state }) => {
        const submitForm = (e) => {
          if (closeModal) closeModal()
          // modifiers.input.rule.shortName.change(
          //   _snakeCase(state.values.input.rule.name)
          // );
          modifiers[''].save()
          e.preventDefault()
        }
        const onNameChange = (name) => _get(modifiers, `${inputPath}.shortName`).change(_snakeCase(name))
        return (
          <form onSubmit={submitForm}>
            {_get(kit, `${inputPath}.name`)({ label: 'Name', onChange: onNameChange })}
            {_get(kit, `${inputPath}.description`)({ label: 'Description' })}
            <input
              type="submit"
              value="Save"
              className="a-btn btn-gap a-btn-primary a-btn-md"
            />
          </form>
        )
      }}
    </Frontier>
  )
}

RuleForm.propTypes = {
  closeModal: PropTypes.func,
  record: PropTypes.object
}
