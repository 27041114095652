import React from 'react';
import {
  Panel,
  Button
} from 'appkit-react'
import {
  useHistory
} from 'react-router-dom'
import styles from '../stylesheets/dashboard.module.css'
import { useQuery } from '@apollo/react-hooks'
import { DASHBOARD_RULESETS } from '../queries/dashboardQueries'

export default function RulesetPanel() {
  const { loading, data: rulesetData } = useQuery(DASHBOARD_RULESETS)
  const history = useHistory()
  const editRulesetFields = id => history.push(`/edit-ruleset-fields/${id}`)
  const manageRuleset = id => history.push(`/manage-ruleset/${id}`)

  return (
    <div>
      <h5 className={styles.heading}>Rulesets</h5>

      <div className="contact-panel d-table w-100">
        <div className="d-flex flex-wrap flex-sm-nowrap">
          <div className="a-mb-20 w-100">
            {!loading && rulesetData.allRulesets.nodes.map(ruleset => (
              <Panel className={`a-p-20 w-100 ${styles.rulesetPanel}`} key={ruleset.id}>
                <div className="a-text-heading a-mt-5">
                  {ruleset.name}
                </div>
                <div className="a-text-caption font-weight-medium a-mt-10 a-text-616">
                  {ruleset.description}
                </div>
                <div className={styles.buttonGroups}>
                  <Button
                    onClick={() => editRulesetFields(ruleset.id)}
                  >
                    Manage ruleset fields
                  </Button>
                  <Button
                    className={styles.manageRulesetButton}
                    onClick={() => manageRuleset(ruleset.id)}
                  >
                    Manage rules
                  </Button>
                </div>
              </Panel>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
