import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, SubMenu } from 'appkit-react';
import { Link } from 'react-router-dom';
import ClassName from 'classnames';
import styles from '../stylesheets/menu.module.css';

export default class LeftNav extends Component {
  render() {
    const { collapsed, onChange } = this.props;
    const menuClass = ClassName('left-nav', {
      closeLeftNav: collapsed === true,
      openLeftNav: collapsed === false
    });
    return (
      <div className={menuClass}>
        <ul className="left-nav-item-list">
          <li className="item user-config">
            <div className="user-name">ED</div>
          </li>
        </ul>

        <Menu
          collapsed={Boolean(collapsed)}
          defaultSelectedKey="1"
          onItemSelect={onChange}
          kind="vertical"
        >
          <Menu.Item propagation={false} key="0">
            <Link className={styles.menuLink} to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.Item propagation={false} key="1">
            <Link className={styles.menuLink} to="/review/files/pending">Files pending review</Link>
          </Menu.Item>
          <Menu.Item propagation={false} key="2">
            <Link className={styles.menuLink} to="/review/files/reviewed">Reviewed files</Link>
          </Menu.Item>
          <Menu.Item key="9">
            <Link className={styles.menuLink} to="/regression-test/new">New Regression Test</Link>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

LeftNav.propTypes = {
  collapsed: PropTypes.bool
};
