/**
 * Dashboard queries
 */
import gql from 'graphql-tag'

export const CREATE_RULESET = gql`
  mutation CreateRuleset($input: CreateRulesetInput!) {
    createRuleset(input: $input) {
      ruleset {
        id
        name
        description
      }
    }
  }
`

export const DASHBOARD_RULESETS = gql`
  query dashboardRulesets {
    allRulesets {
      nodes {
        id
        name
        description
      }
    }
  }
`
