import React from 'react'
import { Panel } from 'appkit-react'
import PropTypes from 'prop-types'
import styles from '../stylesheets/rulesets.module.css'
import RuleCriteriaTable from './ruleCriteriaTable'
import { RuleActionsPanel } from './ruleActionsPanel'
import DeleteRecordButton from 'components/common/components/deleteRecordButton'
import { useMutation } from 'react-apollo'
import { DELETE_RULE, ALL_RULES_BY_RULSET } from '../queries/rulesetQueries'
import CreateRecordButton from 'components/common/components/createRecordButton'
import RuleForm from './ruleForm'
import { updateCacheAfterDelete } from 'lib/helpers/apolloHelpers'

export default function RulePanel({ rule }) {
  const [deleteRule] = useMutation(DELETE_RULE)

  const onDelete = () =>
    deleteRule({
      variables: { ruleId: rule.id },
      update: updateCacheAfterDelete(
        ALL_RULES_BY_RULSET,
        'allRules',
        'deleteRuleById.rule',
        { rulesetId: rule.rulesetId }
      ),
    })

  return (
    <Panel
      className={`a-p-20 w-100 a-mb-20`}
      title={rule.name}
      renderRight={() => (
        <React.Fragment>
          <CreateRecordButton
            className="a-mr-5"
            size="sm"
            buttonText="Edit"
            Form={RuleForm}
            record={rule}
            title="Edit rule"
          />
          <DeleteRecordButton buttonText="Delete" onDelete={onDelete} />
        </React.Fragment>
      )}
    >
      <div className="row">
        <RuleCriteriaTable
          className="col-lg-8"
          criteria={rule.ruleCriteriaByRuleId.nodes.sort((a, b) => a.sequence - b.sequence)}
          rule={rule}
        />
        <div className={`${styles.ruleRightPane} col-lg-4`}>
          <RuleActionsPanel rule={rule} />
        </div>
      </div>
    </Panel>
  )
}

RulePanel.propTypes = {
  rule: PropTypes.object,
}
