import React from 'react'
import { Frontier } from '@pwc/frontier-forms'
import uiKit from 'components/ui-kit/uiKit'
import { useParams } from 'react-router-dom'
// helper imports
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
// graphql imports
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { updateCacheAfterInsert } from 'lib/helpers/apolloHelpers'
import {
  CREATE_RULESET_FIELD,
  UPDATE_RULESET_FIELD,
  ALL_RULESET_FIELDS_BY_RULESET,
} from '../queries/rulesetQueries'


export default function RulesetFieldForm({ closeModal, record }) {
  const client = useApolloClient()
  const { rulesetId } = useParams()

  let initialValues = {
    input: {
      rulesetField: {
        rulesetId: Number(rulesetId),
        name: '',
        code: '',
      },
    },
  }
  let mutation = CREATE_RULESET_FIELD
  let inputPath = 'input.rulesetField'

  // This form is used for both updating and creating, so we need to change
  // parameters accordingly
  if (record) {
    mutation = UPDATE_RULESET_FIELD
    initialValues.input.id = record.id
    // patch is necessary for frontier to auto generate input field
    initialValues.input.rulesetFieldPatch = _omit(record, [ '__typename' ])
    inputPath = 'input.rulesetFieldPatch'
    delete initialValues.input.rulesetField
  }


  return (
    <Frontier
      client={client}
      mutation={mutation}
      uiKit={uiKit}
      initialValues={initialValues}
      mutateProps={{
        // Update the apollo cache after we insert the new rule
        update: !record ? updateCacheAfterInsert(
          ALL_RULESET_FIELDS_BY_RULESET,
          'allRulesetFields',
          'createRulesetField.rulesetField',
          { rulesetId: Number(rulesetId) }
        ) : null,
      }}
    >
      {({ kit, modifiers, state }) => {
        const submitForm = (e) => {
          if (closeModal) closeModal()
          modifiers[''].save()
          e.preventDefault()
        }
        
        return (
          <form onSubmit={submitForm}>
            {_get(kit, `${inputPath}.name`)({ label: 'Name' })}
            {_get(kit, `${inputPath}.code`)({ label: 'Code' })}
            <input
              type="submit"
              value="Save"
              className="a-btn btn-gap a-btn-primary a-btn-md"
            />
          </form>
        )
      }}
    </Frontier>
  )
}

RulesetFieldForm.propTypes = {
  closeModal: PropTypes.func,
  record: PropTypes.object
}
